import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../Contexts/UserContext';

const UserDialog = ({ userId, isOpen, onClose, token }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const {  blockUser, unblockUser } =   useContext(UserContext);
  const handleBlock = () => {
    blockUser(user._id);
    setUser(prev =>( { ...prev,isBlocked:!prev.isBlocked ,}))
  };

  const handleUnblock = () => {
    unblockUser(user._id);
    setUser(prev =>( { ...prev,isBlocked:!prev.isBlocked ,}))
  };

  useEffect(() => {
    if (isOpen && userId) {
      fetchUserDetails();
    }
  }, [isOpen, userId]);

  const fetchUserDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://server.aslahah.com/api/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data.user);
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded shadow-lg max-w-md w-full relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-600">&times;</button>
        {loading ? (
          <div>Loading...</div>
        ) : (
          user && (
            <div>
              <h2 className="text-xl font-bold mb-4">User Details</h2>
              <p><strong>ID:</strong> {user._id}</p>
              <p><strong>Name:</strong> {user.name}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Phone:</strong> {user.mobileNumber}</p>
              <p><strong>Joined:</strong> {new Date(user.createdAt).toLocaleDateString()}</p>
              <p><strong>Status:</strong> {user.isBlocked ? "Blocked" : "Unblocked"}</p>
              <div className="flex justify-end items-center">
        {user.isBlocked ? (
          <button onClick={handleUnblock} className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
            Unblock
          </button>
        ) : (
          <button onClick={handleBlock} className="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
            Block
          </button>
        )}
      </div>
              <button onClick={onClose} className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
                Close
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UserDialog;

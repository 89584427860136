import React, { createContext, useState, useEffect  } from 'react';
import api from '../services/api';
import { useStateContext } from './ContextProvider';

const BookingContext = createContext();

const BookingProvider = ({ children }) => {
    const { loggedIn, checkToken } = useStateContext();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  useEffect(() => {
    if (!loggedIn) {
      const t = checkToken();
      setToken(t);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (token) {
      fetchBookings();
    }
  }, [token]);


  const fetchBookings = async () => {
    setLoading(true);
    try {
      const response = await api.get('https://server.aslahah.com/api/admin/bookings', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBookings(response.data.data);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateBookingStatus = async (id, status) => {
    try {
      const response = await api.put(
        `https://server.aslahah.com/api/admin/bookings/${id}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchBookings(); // Refresh the bookings after update
      return response.data;
    } catch (error) {
      console.error('Error updating booking status:', error);
      throw error;
    }
  };

  return (
    <BookingContext.Provider value={{ bookings, loading, updateBookingStatus }}>
      {children}
    </BookingContext.Provider>
  );
};

export { BookingContext, BookingProvider };

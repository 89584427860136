import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const links = [
    {
      name: "Contact Us",
      link: "contact-us",
    },
    {
      name: "Privacy Policy",
      link: "privacy-policy",
    },
    {
      name: "Terms of use",
      link: "terms-of-use",
    },
    {
      name: "Delete User Data",
      link: "delete-request",
    },
  ];

  return (
    <div className="my-10">
      <p className="dark:text-gray-200 text-gray-700 text-center flex justify-around">
        AS-LAHAH{" "}
        <div className="flex justify-between flex-col lg:flex-row">
          {links.map((link) => (
            <NavLink to={`/${link.link}`} key={link.name}>
              {link.icon}
              <span className="underline mx-10">{link.name}</span>
            </NavLink>
          ))}
        </div>
      </p>
    </div>
  );
};

export default Footer;

import React, { useContext, useState } from 'react';
import { BookingContext } from '../Contexts/BookingContext';
import { BookingCard, Header } from '../Components';

const BookingList = () => {
  const { bookings, loading } = useContext(BookingContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [bookingsPerPage, setBookingsPerPage] = useState(9); // Default value for bookings per page

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  const filteredBookings = bookings.filter(booking => 
    booking.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
    booking.contactNumber.includes(searchTerm) ||
    booking.serviceType.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Logic for pagination
  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const currentBookings = filteredBookings.slice(indexOfFirstBooking, indexOfLastBooking);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle change in bookings per page
  const handleBookingsPerPageChange = (e) => {
    setBookingsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when bookings per page changes
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Orders" />
      <div className="flex items-center mb-4">
        <input
          type="text"
          placeholder="Search bookings..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="mr-2 p-2 border border-color rounded w-full"
        />
        <select
          value={bookingsPerPage}
          onChange={handleBookingsPerPageChange}
          className="p-2 border border-color rounded"
        >
          <option value={6}>6 per page</option>
          <option value={9}>9 per page</option>
          <option value={15}>15 per page</option>
          <option value={21}>21 per page</option>
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {currentBookings.map(booking => (
          <BookingCard key={booking._id} booking={booking} />
        ))}
      </div>
      {/* Pagination controls */}
      <div className="flex justify-center mt-4">
        {Array.from({ length: Math.ceil(filteredBookings.length / bookingsPerPage) }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            className={`px-4 py-2 mx-1 border border-gray-400 rounded ${currentPage === i + 1 ? 'bg-gray-200' : ''}`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BookingList;

import React, { useState } from "react";
import { Header } from "../Components";
import { useStateContext } from "../Contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const { currentColor, setLoggedIn, login } = useStateContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const nav = useNavigate()
  const handleLogin = async () => {
    try {
      const userData = await login(username, password);
      if (userData) {
        setLoggedIn(false);
        nav('/bookings')
      }
    } catch (error) {
      console.log(error);
      alert("Something went wrong, Please try again");
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Login" />

      <div className="flex flex-col gap-5 items-center mt-6 pb-6">
        <div className="w-full flex gap-5 items-center border-color border-b-1 pb-2">
          <p className="font-semibold text-xl dark:text-gray-200 w-1/3">
            Username
          </p>
          <input
            className="border p-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            type="text"
            placeholder="Enter your username"
          />
        </div>

        <div className="w-full flex gap-5 items-center border-color border-b-1 pb-2">
          <p className="font-semibold text-xl dark:text-gray-200 w-1/3">
            Password
          </p>
          <input
            className="border p-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Enter your password"
          />
        </div>
      </div>

      <div className="mt-5">
        <button
          type="button"
          onClick={handleLogin}
          style={{
            backgroundColor: currentColor,
            color: "white",
            borderRadius: "10px",
          }}
          className="text-full p-3 w-full hover:drop-shadow-xl hover:bg-white"
        >
          Login
        </button>
      </div>
    </div>
  );
};
export default Login;

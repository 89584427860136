import React from "react";
import { Header } from "../Components";

const Terms = () => {
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Terms of use" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Terms of Use</h1>

        <div className="space-y-6">
          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              Introduction
            </h2>
            <p className="mt-2 text-gray-700">
              Welcome to Aslahah! These Terms of Use govern your use of our
              mobile application and the services provided through it. By
              accessing or using Aslahah, you agree to comply with and be bound
              by these Terms of Use. If you do not agree to these terms, please
              do not use our application.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              1. Acceptance of Terms
            </h2>
            <p className="mt-2 text-gray-700">
              By downloading, installing, or using the Aslahah app, you agree to
              these Terms of Use and our Privacy Policy. We may update these
              terms from time to time, and we will notify you of any changes by
              posting the new terms within the app. Your continued use of the
              app after such changes will constitute your acceptance of the new
              terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              2. Use of the App
            </h2>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              Eligibility
            </h3>
            <p className="mt-2 text-gray-700">
              You must be at least 18 years old to use our app. By using
              Aslahah, you represent and warrant that you meet this age
              requirement.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              Account Registration
            </h3>
            <p className="mt-2 text-gray-700">
              To access certain features of the app, you may need to create an
              account. You agree to provide accurate, current, and complete
              information during the registration process and to update such
              information as necessary.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              User Responsibilities
            </h3>
            <p className="mt-2 text-gray-700">
              You are responsible for maintaining the confidentiality of your
              account information and for all activities that occur under your
              account. You agree to notify us immediately of any unauthorized
              use of your account.
            </p>
            <h3 className="text-xl font-semibold text-gray-700 mt-4">
              Prohibited Conduct
            </h3>
            <p className="mt-2 text-gray-700">
              You agree not to use the app for any unlawful purpose or in any
              way that could harm the app, its users, or third parties.
              Prohibited conduct includes, but is not limited to:
            </p>
            <ul className="list-disc list-inside mt-2 text-gray-700">
              <li>
                Engaging in any activity that interferes with or disrupts the
                app.
              </li>
              <li>Uploading or transmitting viruses or malicious code.</li>
              <li>
                Attempting to gain unauthorized access to other users' accounts
                or our systems.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              3. Services Provided
            </h2>
            <p className="mt-2 text-gray-700">
              Aslahah connects users with technicians for various services.
              While we strive to ensure that our service providers are qualified
              and reliable, we do not guarantee the quality of their services.
              Any issues or disputes with service providers should be resolved
              directly with them.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">4. Payment</h2>
            <p className="mt-2 text-gray-700">
              Payment for services booked through the app will be processed via
              the payment methods available in the app. You agree to pay all
              fees and charges associated with your use of the services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              5. Cancellation and Refunds
            </h2>
            <p className="mt-2 text-gray-700">
              Cancellation and refund policies for services booked through
              Aslahah are determined by the individual service providers. Please
              review the specific terms provided by the service provider before
              booking.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              6. Intellectual Property
            </h2>
            <p className="mt-2 text-gray-700">
              All content and materials available on the app, including but not
              limited to text, graphics, logos, and software, are the property
              of Aslahah or its licensors and are protected by intellectual
              property laws. You agree not to reproduce, distribute, or create
              derivative works from any content without our express written
              permission.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              7. Disclaimer of Warranties
            </h2>
            <p className="mt-2 text-gray-700">
              Aslahah is provided on an "as is" and "as available" basis. We do
              not warrant that the app will be uninterrupted or error-free. To
              the fullest extent permitted by law, we disclaim all warranties,
              express or implied, including but not limited to warranties of
              merchantability, fitness for a particular purpose, and
              non-infringement.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              8. Limitation of Liability
            </h2>
            <p className="mt-2 text-gray-700">
              In no event shall Aslahah, its affiliates, or its licensors be
              liable for any indirect, incidental, special, consequential, or
              punitive damages arising out of or related to your use of the app
              or the services provided through it.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              9. Indemnification
            </h2>
            <p className="mt-2 text-gray-700">
              You agree to indemnify and hold Aslahah, its affiliates, and its
              licensors harmless from any claims, damages, losses, liabilities,
              and expenses (including attorney's fees) arising out of or related
              to your use of the app, your violation of these Terms of Use, or
              your violation of any rights of a third party.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              10. Governing Law
            </h2>
            <p className="mt-2 text-gray-700">
              These Terms of Use shall be governed by and construed in
              accordance with the laws of [Your Country/State], without regard
              to its conflict of law principles.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800">
              11. Contact Us
            </h2>
            <p className="mt-2 text-gray-700">
              If you have any questions or concerns about these Terms of Use,
              please contact us at:
            </p>
            <p className="mt-2 text-gray-700">
              Email: support@aslahah.com <br />
              Address: [Your Company Address]
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
export default Terms;

import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { ContextProvider } from "./Contexts/ContextProvider";
import { UserProvider } from "./Contexts/UserContext";
import { BookingProvider } from "./Contexts/BookingContext";

ReactDOM.render(
  <ContextProvider>
    <UserProvider>
      <BookingProvider>
        <App />
      </BookingProvider>
    </UserProvider>
  </ContextProvider>,
  document.getElementById("root")
);

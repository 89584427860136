import React from 'react';
import {Header} from '../Components'

const PrivacyPolicy = () => {
  let styles={margin:"30px"};
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Privacy Policy" />
      <p style={styles} >
      This Privacy Policy (the “Policy”) governs the manner in which the Platform collects, uses, maintains and discloses information of its users. The Policy also describes the practices that We apply to such user information, user’s privacy rights and choices regarding their information. To clarify, this Policy applies to all users of the Platform.
<br />
<br />
By accessing and using the Platform, providing Your Personal Information, or by otherwise signalling Your agreement when the option is presented to You, You consent to the collection, use, and disclosure of information described in this Policy and Terms of Use and we disclaim all the liabilities arising therefrom. If You do not agree with any provisions of this Policy and/or the Terms of Use, You should not access or use the Platform or engage in communications with us and are required to leave the Platform immediately. If any information You have provided or uploaded on the Platform violates the terms of this Policy or Terms of Use, we may be required to delete such information upon informing You of the same and revoke Your access if required without incurring any liability to You. Capitalized terms used but not defined in this Privacy Policy can be found in our Terms of Use. Please read this Policy carefully prior to accessing our Platform and using any of the services or products offered therein. If you have any questions, please contact us at the contact information provided below.

<br />
<br />
<b>1.PERSONAL INFORMATION</b><br /><br />
<b>"Personal Information"</b> shall mean the information which identifies a Learner i.e., first and last name, identification number, email address, age, gender, location, photograph and/or phone number provided at the time of registration or any time thereafter on the Platform.
<br /><br />
<b>“Sensitive Personal Information”</b> shall include (i) passwords and financial data (except the truncated last four digits of credit/debit card), (ii) health data, (iii) official identifier (such as biometric data, aadhar number, social security number, driver’s license, passport, etc.,), (iv) information about sexual life, sexual identifier, race, ethnicity, political or religious belief or affiliation, (v) account details and passwords, or (vi) other data/information categorized as ‘sensitive personal data’ or ‘special categories of data’ under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, General Data Protection Regulation (GDPR) and / or the California Consumer Privacy Act (CCPA) (“Data Protection Laws”) and in context of this Policy or other equivalent / similar legislations.
<br /><br />
Usage of the term <b> 'Personal Information'</b>shall include <b> 'Sensitive Personal Information'</b> as may be applicable to the context of its usage.
<br /><br />

2.<b>INFORMATION WE COLLECT:</b><br /><br />

We may collect both personal and non-personal identifiable information from You in a variety of ways, including, but not limited to, when You visit our Platform, register on the Platform, and in connection with other activities, services, features or resources we make available on our Platform. However, please note that-

<li>We do not ask You for Personal Information unless we truly need it; like when You are registering for any Content on the Platform.</li>
<li>We do not share Your Personal Information with anyone except to comply with the applicable laws, develop our products and services, or protect our rights.</li>
<li>We do not store Personal Information on our servers unless required for the on-going operation of our Platform.</li>
<br /><br />

a. <b>Personal Identifiable Information:</b> We may collect personal-identifiable information such as Your name and emails address to enable Your access to the Platform and services/products offered therein. We will collect personal-identifiable information from You only if such information is voluntarily submitted by You to us. You can always refuse to provide such personal identification information; however, it may prevent You from accessing services or products provided on the Platform or from engaging in certain activities on the Platform.
<br /><br />
b. <b>Non-Personal Identifiable Information:</b> When You interact with our Platform, we may collect non-personal-identifiable information such as the browser name, language preference, referring site, and the date and time of each user request, operating system and the Internet service providers utilized and other similar information.
<br /><br />
c. <b>Cookies:</b> To enhance User experience, our Platform may use 'cookies'. A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns for record-keeping purposes. You may choose to set Your web browser to refuse cookies, or to notify You when cookies are being sent; however, please note that in doing so, some parts of the Platform may not function properly.
<br /><br />
3. <b>HOW WE USE and SHARE THE INFORMATION COLLECTED</b>
<br /><br />

We may collect and use Your Personal Information for the following purposes:
<br /><br />

a. <b>To provide access to our Platform and/or the services/products offered therein:</b> We use the Your information as collected by us to allow You to access the Platform and the services/products offered therein, including without limitation to provide customer service, fulfil purchases through the Platform, verify User information and to resolve any glitches with our Platform. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between You and us.
<br /><br />
b. <b>To improve our Platform and maintain safety:</b> We use Your information to improve and customize the Platform and services/products offered by us. Further, we also use Your information to prevent, detect, investigate, and take measures against criminal activity, fraud, misuse of or damage to our Platform or network, and other threats and violations to a third party's or our rights and property, or the safety of our Users, or others. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between You and us.
<br /><br />
c. <b>To communicate with You or market our services/products:</b> We may use the email address submitted by You to communicate with You about Your orders on our Platform, our offers, new products, services or even receive Your feedback on the Platform or any services/products offered therein. It may also be used to respond to Your inquiries, questions, and/or other requests. If at any time You would like to unsubscribe from receiving future emails, please write to us at the contact information provided below. The legal basis for this processing is consent or, where applicable, our legitimate interests in the proper administration of our Platform, and/or the performance of a contract between You and us.
<br /><br />
We do not use Personal Information for making any automated decisions affecting or creating profiles other than what is described in this Policy.
<br /><br />
We do not sell, trade, or otherwise exploit Your personal-identifiable information to others. Limited to the purposes stated hereinabove, we may share generic aggregated demographic information not linked to any personal-identifiable information regarding visitors and Users with our business partners and trusted affiliates.
<br /><br /><br />

4 <b>YOUR CHOICES:</b>
<br /><br />
a. <b>Limit the information You provide:</b> You always have an option to choose the information You provide to us, including the option to update or delete Your information. However, please note that lack of certain information may not allow You access to the Platform or any of its features, in part or in full. For example: information required for Your registration on the Platform.
<br /><br />
b. <b>Limit the communications You receive from us:</b> Further, You will also have the option to choose what kind of communication You would like to receive from us. However, there may be certain communications that are required for legal or security purposes, including changes to various legal agreements, that you may not be able to limit.
<br /><br />
c. <b>Reject Cookies and other similar technologies:</b> You may reject or remove cookies from Your web browser; You will always have the option to change the default settings on Your web browser if the same is set to ‘accept cookies’. However, please note that some of the services/products offered on the Platform may not function or be available to You, when the cookies are rejected, removed or disabled.
<br /><br />

      </p>
    </div>
  );
};
export default PrivacyPolicy;
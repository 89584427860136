import axios from "axios";
import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const [isClicked, setIsClicked] = useState(initialState);

  const [loggedIn, setLoggedIn] = useState(true);
  const [clicked, setClicked] = useState(true);

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  const tokenStorageKey = "authToken";

  // Function to login a user
  async function login(id, password) {
    try {
      const response = await axios.post(
        "https://server.aslahah.com/api/admin/login",
        {
          id,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Assuming the response contains the authentication token
      const token = response.data.admin.token;

      // Store the token in localStorage or any other storage mechanism
      localStorage.setItem(tokenStorageKey, token);

      // Handle successful login
      console.log("Login successful:", response.data);
      return response.data;
    } catch (error) {
      // Handle errors
      if (error.response) {
        console.error("Login error:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error in request setup:", error.message);
      }
      throw error;
    }
  }

  function logout() {
    localStorage.removeItem(tokenStorageKey);
    setLoggedIn(true);
    console.log("Logout successful");
  }

  // Function to check for token on website load
  function checkToken() {
    const token = localStorage.getItem(tokenStorageKey);
    if (token) {
      console.log("Token found:", token);
      return token;
    } else {
      console.log("No token found");
      return null;
    }
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        login,
        logout,
        checkToken,
        tokenStorageKey,
        clicked,
        setClicked,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);

import React, { createContext, useState, useEffect } from "react";
import api from "../services/api";
import { useStateContext } from "./ContextProvider";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { loggedIn, checkToken } = useStateContext();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  useEffect(() => {
    if (!loggedIn) {
      const t = checkToken();
      setToken(t);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (token) {
      fetchUsers();
    }
  }, [token]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/admin/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setUsers(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  const fetchUserById = async (id) => {
    try {
      const response = await api.get(`/admin/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching user:", error);
      return null;
    }
  };

//   const addUser = async (user) => {
//     try {
//       const response = await api.post("/admin/users", user, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setUsers((prevUsers) => [...prevUsers, response.data]);
//     } catch (error) {
//       console.error("Error adding user:", error);
//     }
//   };

//   const updateUser = async (id, updatedUser) => {
//     try {
//       await api.put(`/admin/users/${id}`, updatedUser, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setUsers((prevUsers) =>
//         prevUsers.map((user) => (user._id === id ? updatedUser : user))
//       );
//     } catch (error) {
//       console.error("Error updating user:", error);
//     }
//   };

//   const deleteUser = async (id) => {
//     try {
//       await api.delete(`/admin/users/${id}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

  const blockUser = async (id) => {
    try {
      await api.put(`/admin/users/block/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === id ? { ...user, isBlocked: true } : user
        )
      );
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  const unblockUser = async (id) => {
    try {
      await api.put(`/admin/users/unblock/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === id ? { ...user, isBlocked: false } : user
        )
      );
    } catch (error) {
      console.error("Error unblocking user:", error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        users,
        loading,
        fetchUsers,
        fetchUserById,
        blockUser,
        unblockUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };

import React, { useContext } from "react";
import { UserContext } from "../Contexts/UserContext";

const UserCard = ({ user }) => {
  const {  blockUser, unblockUser } =   useContext(UserContext);
  const handleBlock = () => {
    blockUser(user._id);
  };

  const handleUnblock = () => {
    unblockUser(user._id);
  };

  console.log(user)
  return (
    <div className="max-w-xxl rounded overflow-hidden shadow-lg bg-main-bg border border-color p-4">
      <div className="flex items-center mb-4">
        <img
          className="w-10 h-10 rounded-full mr-4"
          src={user.profilePicture || "https://via.placeholder.com/150"}
          alt={user.name}
        />
        <div className="text-lg font-semibold">{user.name}</div>
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Email : </span> {user.email}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Mobile Number : </span> {user.mobileNumber}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Blocked : </span> {user.isBlocked?"Blocked":"Active"}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Joined : </span> {new Date(user.createdAt).toLocaleDateString()}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Id : </span> {user._id}
      </div>
      <div className="flex justify-end items-center">
        {user.isBlocked ? (
          <button onClick={handleUnblock} className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700">
            Unblock
          </button>
        ) : (
          <button onClick={handleBlock} className="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
            Block
          </button>
        )}
      </div>
    </div>
  );
};

export default UserCard;

import React from "react";
import { Header } from "../Components";
import { useNavigate } from "react-router-dom";
import logo from "../Data/logo.png";

const Home = () => {
  const nav = useNavigate();

  const handleClick = () => {
    nav("/login");
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="" title="Home" />

      {/* <button
        type="button"
        onClick={handleClick}
        style={{
          backgroundColor: "azure",
          color: "black",
          borderRadius: "10px",
        }}
        className="text-full p-3 w-full hover:drop-shadow-xl hover:bg-white"
      >
        Login
      </button> */}
      <img
        style={{ width: "50%", height: "10%", margin: "auto" }}
        src={logo}
        alt=""
      />
    </div>
  );
};
export default Home;

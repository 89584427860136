import React, { useState } from 'react';
import { useContext } from 'react';
import { BookingContext } from '../Contexts/BookingContext';
import { useStateContext } from '../Contexts/ContextProvider';
import UserDialog from './UserDialog';


const BookingCard = ({ booking }) => {
  const { updateBookingStatus } = useContext(BookingContext);
  const { checkToken } = useStateContext();
  const [status, setStatus] = useState(booking.status);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      await updateBookingStatus(booking._id, status);
      alert('Booking status updated successfully');
    } catch (error) {
      console.error('Error updating booking status:', error);
      alert('Failed to update booking status');
    }
  };

  const handleUserClick = () => {
    setIsDialogOpen(true);
  };

  console.log(booking)

  return (
    <div className="max-w-xxl rounded overflow-hidden shadow-lg bg-main-bg border border-color p-4">
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Address:</span> {booking.address}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Contact Number:</span> {booking.contactNumber}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Service Type:</span> {booking.serviceType}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Service For:</span> {booking.serviceFor}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Preferred Date:</span> {new Date(booking.preferredDate).toLocaleDateString()}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">Preferred Time:</span> {booking.preferredTime}
      </div>
      <div className="mb-2 text-sm text-gray-700">
        <span className="font-semibold">User Id :</span>  <button onClick={handleUserClick} className="text-blue-500 underline">{booking.userId}</button>
      </div>
      <div className="mb-2 text-sm text-gray-700">
      <span className="font-semibold">Booking Status:</span>
        <ul>
          {booking.bookingStatus.map((statusObj, index) => (
            <li key={index}>{statusObj.status} - {new Date(statusObj.updatedAt).toLocaleString()}</li>
          ))}
        </ul>
      </div>
      <div className="flex items-center mb-4">
        <select value={status} onChange={handleStatusChange} className="p-2 border border-color rounded">
          <option value="pending">Pending</option>
          <option value="confirmed">Confirmed</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>
        <button
          onClick={handleUpdate}
          className="ml-4 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
        >
          Update Status
        </button>
      </div>
      <UserDialog
        userId={booking.userId}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        token={checkToken()}
      />
    </div>
  );
};

export default BookingCard;
